.nwe-footer {
  align-items: stretch;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  margin: 20px auto;
  padding: 20px 0px;
  .social-title {
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    align-items: center;
    display: flex;
    justify-content: center;
    order: -1;
    h2 {
      font-size: 4rem;
    }
  }
  .social-wrap {
    display: flex;

    .social-icons-wrap {
      // width: 100%;
      .social-icons {
        width: 50%;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media (min-width: 1025px) {
  .nwe-footer {
    min-height: 470px;
    padding: 0px;
    .social-title {
      background-color: #f8951d;
      background-image: url("../../images/follow-bkg.png");
      order: 0;
      padding: 0px;
    }
    .social-wrap {
      min-height: 475px;
      align-items: center;
      .social-icons {
        width: auto;
      }
    }
  }
}
