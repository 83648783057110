@import url("https://fonts.googleapis.com/css?family=Bebas+Neue|Open+Sans&display=swap");
//  #f8951d nwe orange

// =============== Base Styles ===============
html {
  font-size: 62.5%;
}
h1,
h2,
h3,
h4,
.bebas {
  font-family: "Bebas Neue", cursive;
}
.openSans {
  font-family: "Open Sans", sans-serif;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  &.locked {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
}

// =============== Bootstrap Add-ons ===============

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute!important;
  width: 1px!important;
  height: 1px!important;
  padding: 0!important;
  margin: -1px!important;
  overflow: hidden!important;
  clip: rect(0,0,0,0)!important;
  white-space: nowrap!important;
  border: 0!important;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

// =============== General Styles ===============

.nwe-wrap {
  margin: 20px 0px;
}
.row {
  max-width: 1350px;
  margin: 0 auto;
}
.footer-bar {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  span {
    display: block;
  }
}
.loadState {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 200;
  background-color: #fff;
  transition: all 0.25s ease;
  img {
    width: 50%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.loaded {
    opacity: 0;
    display: none;
    img {
      top: 7%;
      left: 48.9%;
    }
  }
}

@media (min-width: 1025px) {
  .footer-bar {
    span {
      display: inline-block;
      margin-left: 4rem;
    }
  }
}
