.nwe-header-wrap {
  height: 100%;
  width: 100%;

  .nwe-header {
    max-height: 60px;
    max-width: 1350px;
    width: 100%;
    margin: 0 auto;
    &.mobile {
      max-width: 100%;
      .mobile-header-img {
        flex-grow: 1;
        display: block;
        justify-content: center;
        text-align: center;
        img {
          width: 100%;
          max-width: 160px;
        }
      }
      .navbar {
        float: left;
      }
    }
    .nwe-header_links {
      display: flex;
      // this is not some code
      // Lost something four
      // as something else
      align-items: center;
      font-size: 3rem;
      a {
        color: #f8951d;
        margin-right: 5rem;
      }
    }
    .nwe-header_logo {
      // display: flex;
      justify-content: center;
      text-align: center;
      img {
        max-height: 100px;
      }
    }
    .nwe-header_social {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .social_row {
        margin: 0;
      }
      .social-icons {
        img {
          width: auto;
          max-height: 23px;
          max-width: 100%;
        }
      }
    }
  }
}
.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  transform: translate3d(0, -100vh, 0);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  width: 100vw;
  height: 100vh;
  z-index: 300;
  background-color: #fff;
  &.show {
    transform: translate3d(0, 0, 0);
  }
  .close {
    font-size: 3.5rem;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .mm-container {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .mm-img {
    margin-top: 20px;
    img {
      max-width: 160px;
      width: 100%;
      height: auto;
    }
  }
  .mm-list {
    margin-top: 80px;
    .mm-link {
      font-size: 5rem;
      color: #f8951d;
      margin-right: 5rem;
      display: block;
      margin: 0;
    }
  }
}
.bg-light {
  background-color: none;
}
