.slide_wrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 8rem auto 4rem;
}
.slide_hero {
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center;
}
.hero-player-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.hero-payer {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  &.show {
    opacity: 1;
  }
}
.slide-cta {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in-out;
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  &.hide {
    opacity: 0;
    z-index: -10;
  }
  .hero-title {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #fff;
    font-size: 2.4rem;
    margin: 0px;
  }
}

.arrow-left {
  position: absolute;
  top: 50%;
  z-index: 100;
  left: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  img {
    width: 50px;
    height: auto;
  }
}
.arrow-right {
  position: absolute;
  top: 50%;
  z-index: 100;
  right: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  img {
    width: 50px;
    height: auto;
  }
}

.itemsContainer {
  width: 100%;
  overflow: hidden;
  color: #000;
  position: relative;
}

.items-wrap {
  width: 100%;
  min-width: 9000px;
  position: absolute;
  // left: 0px;
  transition: left 0.35s ease-out;
}
.slide_item {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  transition: opacity 0.85s ease-in;
  float: left;
  cursor: pointer;
  box-sizing: border-box;
  border: solid 3px white;
  &:hover {
    p {
      opacity: 1;
    }
  }
  img {
    height: auto;
    width: 100%;
  }
  p {
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
    position: absolute;
    opacity: 0;
    bottom: 0px;
    margin: 0px 0px;
    background-color: rgba(248, 149, 29, 0.8);
    width: 100%;
    padding: 10px;
  }
}
.play-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  background-color: #f8951d;
  border: 0px solid transparent;
  .play {
    display: block;
    height: 0;
    width: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #fff;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
  }
}

.pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  span {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #9e9e9e;
    margin: 0 5px;
    cursor: pointer;
    &.active {
      background-color: #f8951d;
    }
  }
}
