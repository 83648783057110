.nwe-contact-us {
  min-height: 470px;
  align-items: stretch;
  margin-bottom: 20px;
  .contact-us_title {
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    color: #000;
    align-items: center;
    display: flex;
    justify-content: center;
    h2 {
      font-size: 4rem;
    }
  }
  .info-wrap {
    min-height: 475px;
    display: flex;
    align-items: center;
  }
  .contact-us_info {
    width: 100%;
    .info-col {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      min-width: 150px;
      img {
        max-height: 75px;
        width: auto;
        margin-bottom: 20px;
      }
      a {
        color: #212529;
      }
    }
  }
}
@media (min-width: 1025px) {
  .nwe-contact-us {
    .contact-us_title {
      background-color: #151515;
      background-image: url("../../images/contact-bkg.png");
      color: #fff;
    }
    .info-wrap {
    }
    .contact-us_info {
      .info-col {
      }
    }
  }
}
