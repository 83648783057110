.nwe-about-us {
  margin-bottom: 20px;

  // Sub Elements
  img {
    max-width: 100%;
  }

  .intro {
    text-align: left;
    margin-bottom: 0;

    // Sub Elements
    h2 {
      font-size: 5.5rem;
      color: #f8951d;
      margin-bottom: 4.5rem;
    }
  }

  .nwe-copy {
    margin-bottom: 4rem;
  }

  .pillars-wrap {
    background-color: #fff;
    width: 100%;
    position: relative;

    &.mobile {
      flex-direction: column;

      // Sub Elements
      .pillar {
        img {
          margin: 20px auto 80px;
        }

        // Sub Elements
        .btn {
          margin: 0 auto;
        }
      }
    }

    .pillar-chevron {
      fill: white;
      position: absolute;
      z-index: 2;
      width: 100%;
      top: -2px;
      left: 0;
    }

    .pillar {
      padding: 50px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      color: #fff;

      // Sub Elements
      .pillar__img {
        width: 100%;
        height: auto;
        max-width: 320px;
        margin: 0 auto 40px;
      }

      .pillar__copy {
        margin-bottom: 8rem;
      }

      .finishingImg {
          max-width: 100%;
        }

      h3 {
        font-size: 2.3rem;
      }

      .btn {
        width: 100%;
        font-size: 3.5rem;
        border: 2px solid #fff;
      }
    }

  }
}

// ********** Finishing **********

.nwe-finishing-content {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  background: #f8951d;
  color: #fff;
  padding: 7rem 0 0;
  left: -10000px;
  top: 0;

  &.show {
    left: 0;
  }

  // Sub Elements
  .btn {
    margin-bottom: 15px;
    font-size: 3.5rem;
    border: 2px solid #fff;
    width: 100%;
  }
}

// =============== Carousel ===============

.carousel {
  margin-bottom: 30px;
}

.carousel-item img {
  max-height: 380px;
}

// =============== Media Queries ===============

@media (max-width: 500px) {
  .pillar {
    &.fmain {
      min-height: 600px !important;
      max-height: 610px;
      margin-bottom: 60px;

      // Sub Elements
      img {
        margin: 150px auto 40px !important;
      }
    }

    &.fcopy {
      min-height: auto;

      &:last-child {
        padding: 0 50px 200px !important;
      }
    }
  }

  .fpillar {

    // Sub Elements
    .finishingImg {
      margin-bottom: 30px;
    }

  }
}

@media (max-width: 1024px) {

  .fcopy {
    background-image: none;
    justify-content: flex-start;
  }
}

@media (min-width: 1025px) {
  .nwe-about-us {

    // Sub Elements
    .intro {
      text-align: center;
    }

    // Sub Elements
    .pillars-wrap {

      // Sub Elements
      .pillar {
        justify-content: flex-end;
        padding: 75px 50px;
        min-height: 1000px;
      }

      .pillar-chevron {
        top: -1px;
      }
    }
  }

  .fpillar {

    // Sub Elements
    .finishingImg {
      margin: 0 auto 20px;
      max-width: 80%;
    }

    h3 {
      font-size: 2.3rem;
    }

    .btn {
      font-size: 3.5rem;
      border: 2px solid #fff;
    }
  }

  .nwe-finishing-content {
    padding: 13rem 50px 0;
  }
}

// ********** Prefers Reduced Motion **********
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
      transition: none;
  }
}