.loader-component-wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
  background-color: #fff;
  &.hide {
    display: none;
  }
  .loader-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      width: 100%;
      height: auto;
      max-height: 100vh;
      max-width: 300px;
    }
  }
}
